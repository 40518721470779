<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Pay & Confirm"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />


    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip
          :showBrokerButton="false"
          :showPie="false"
          :showWine="false"
        />
      </div>

      <div class="col-span-8">
        <div v-if="showdata">
          <wine-detail :item="stock" :cases="quantity" :overrideprice="'GBP ' +currencyFormatter(calcTotal())"/>

          <div class="grid grid-cols-2 gap-4 mt-5 pb-5 m-10">
            <div class="pr-20 mt-10">
              <span v-html="stock.description"></span>
            </div>

            <div class="grid grid-cols-5 gap-4">
              <div class="col-span-3 mt-6">
                <span class="oeno_portfolio_winetitle">Sub Total</span>
              </div>
              <div class="col-span-2 mt-6">
                <span class="float-right oeno_portfolio_price"
                  >GBP&nbsp;{{ currencyFormatter(calcSubTotal()) }}</span
                >
              </div>

              <div class="col-span-3 mt-6" v-if="dutyCharge() > 0">
                <span class="oeno_portfolio_winetitle">Duty Charge</span>
              </div>
              <div class="col-span-2 mt-6" v-if="dutyCharge() > 0">
                <span class="float-right oeno_portfolio_price"
                  >GBP&nbsp;{{ currencyFormatter(dutyCharge()) }}</span
                >
              </div>

              <div class="col-span-3 mt-6" v-if="vatCharge() > 0">
                <span class="oeno_portfolio_winetitle">VAT</span>
              </div>
              <div class="col-span-2 mt-6" v-if="vatCharge() > 0">
                <span class="float-right oeno_portfolio_price"
                  >GBP&nbsp;{{ currencyFormatter(vatCharge()) }}</span
                >
              </div>

              <div class="col-span-3 mt-6" v-if="deliveryCharge() > 0">
                <span class="oeno_portfolio_winetitle">Delivery</span>
              </div>
              <div class="col-span-2 mt-6" v-if="deliveryCharge() > 0">
                <span class="float-right oeno_portfolio_price"
                  >GBP&nbsp;{{ currencyFormatter(deliveryCharge()) }}</span
                >
              </div>

              <div class="col-span-3 mt-6">
                <span class="oeno_portfolio_winetitle">Total</span>
              </div>
              <div class="col-span-2 mt-6">
                <span class="float-right oeno_portfolio_price"
                  >GBP&nbsp;{{ currencyFormatter(calcTotal()) }}</span
                >
              </div>

              <div class="col-span-5">
                <button
                  @click="modalActive = true"
                  class="w-72 h-10 oeno_button_black mt-10 align-center"
                  :disabled="processing"
                  >{{
                    processing ? "Processing" : "Continue And Pay"
                  }}</button
                >
              </div>

              <md-dialog-confirm
                :md-active.sync="modalActive"
                md-title="Terms and Conditions"
                md-content="<p><b>
Introduction:</b><br/>
These terms and conditions govern the sale of wine between OENO Future (hereinafter referred to as 'Seller') . By purchasing wine from the Seller, the Buyer agrees to be bound by these terms and conditions.
</p>
<p><b>
Purchase and Payment:</b><br/>
The Buyer agrees to purchase the wine from the Seller for the price of [insert price]. Payment for the wine will be made in full at the time of purchase, and can be made via [insert accepted forms of payment].
</p>
<p><b>

Shipping and Delivery:</b><br/>
The Seller agrees to ship the wine to the Buyer at the address provided by the Buyer. The Buyer is responsible for any shipping and handling costs. The Buyer agrees to accept delivery of the wine upon receipt.
</p>
<p><b>

Warranty:</b><br/>
The Seller represents and warrants that the wine will be free from defects in material and workmanship at the time of delivery. If the wine is found to be defective, the Buyer may return the wine to the Seller for a full refund or replacement.
</p>
<p><b>

Limitation of Liability:</b><br/>
In no event shall the Seller be liable to the Buyer for any damages, including but not limited to indirect, incidental, special, or consequential damages, arising out of or in connection with the sale of the wine.
</p>
<p><b>

Governing Law:</b><br/>
These terms and conditions shall be governed by and construed in accordance with the laws of UK Law.
</p>
<p><b>

Entire Agreement:</b><br/>
These terms and conditions constitute the entire agreement between the parties regarding the sale of wine and supersede all prior agreements or understandings, whether written or oral.
</p>
<p><b>

Severability:</b><br/>
If any provision of these terms and conditions is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
</p>
<p><b>

Waiver:</b><br/>
The failure of the Seller to enforce any right or provision in these terms and conditions shall not constitute a waiver of such right or provision unless acknowledged and agreed to by the Seller in writing.
</p>
<p>

By accepting these terms and conditions, the Buyer agrees to be bound by the terms and conditions set forth above.
</p>
"
                md-confirm-text="Agree"
                md-cancel-text="Disagree"
                @md-cancel="modalActive = false"
                @md-confirm="processSell()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import WineDetail from "@/components/wine/WineDetailStockTable.vue";

export default {
  data() {
    return {
      showdata: false,
      stock: [],
      quantity: 0,

      processing: false,
      modalActive: false,

      orderData: null,
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
    WineDetail,
  },

  mounted() {
    this.stock = this.$store.state.orderData.stock;
    this.quantity = this.$store.state.orderData.quantity;
    this.orderData = this.$store.state.orderData;

    //
    // So three routes the first is our bond to our bond so no charges other than the cost to the wine, our bond to another bond again no crges ther than the cost as x% has been added to manage it and the final one is our bond to our of bond and we need to add vat and duty
    //
    if (this.orderData.target == "outhome") {
      // So add deleivery and duty

      this.orderData.delivery = this.deliveryCharge();
      this.orderData.duty = this.dutyCharge();
      this.orderData.vat = this.vatCharge();
    }

    this.showdata = true;
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
  },
  methods: {
    calculateWineDuty(price, bottleSize, alcoholStrength) {
      let duty = 0;
      if (bottleSize <= 150) {
        if (alcoholStrength <= 22) {
          duty = price * 0.08;
        } else if (alcoholStrength > 22 && alcoholStrength <= 25) {
          duty = price * 0.11;
        } else {
          duty = price * 0.14;
        }
      } else if (bottleSize > 150) {
        if (alcoholStrength <= 22) {
          duty = price * 0.16;
        } else if (alcoholStrength > 22 && alcoholStrength <= 25) {
          duty = price * 0.22;
        } else {
          duty = price * 0.28;
        }
      } else {
        throw new Error(
          "Invalid bottle size. Please use a positive value in ml."
        );
      }
      return duty;
    },
    processSell() {
      /* Store the order data inot the store */

      this.$store.dispatch("storeOrderData", this.orderData).then(() => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.token,
        };

        HTTP.post(
          "/api/" + this.orderData.target + "-make-payment",
          this.orderData,
          { headers: headers }
        )
          .then((response) => {
            this.updateStatus = false;

            if (response.data.status) {
              window.location.href = response.data.data.url;
            } else {
              this.$notify({
                group: "oeno",
                title: "Buy Wine Error",
                text: "Sorry there seems to have been an error processing your request, please try again later.",
                type: "error",
              });
            }
          })
          .catch((error) => {
            this.updateStatus = false;

            this.$notify({
              group: "oeno",
              title: "Buy Wine Error",
              text: "Sorry there seems to have been an error processing your request, please try again later.",
              type: "error",
            });

            console.log(error);
          });
      });
    },
    validateCases(max) {
      if (parseFloat(this.quantity) < 1) {
        this.validCases = false;
      } else if (parseFloat(this.quantity) > max) {
        this.validCases = true;
        this.quantity = this.maxcases;
      } else {
        this.validCases = true;
      }
    },

    deliveryCharge() {
      var retValue = 0;

      if (this.orderData.target == "outhome") {
        retValue = parseFloat(process.env.VUE_APP_BUYER_CHARGE_DELIVERY);
      }

      return retValue;
    },

    dutyCharge() {
      if (this.orderData.target == "outhome") {
        return this.orderData.subtotal * this.stock.duty_rate;
      } else {
        return 0;
      }
    },

    vatCharge() {
      if (this.orderData.target == "outhome") {
        return (
          (this.orderData.subtotal + this.dutyCharge()) *
          parseFloat(process.env.VUE_APP_BUYER_VAT_PERC)
        );
      } else {
        return 0;
      }
    },

    calcSubTotal() {
      return this.orderData.subtotal;
    },

    calcTotal() {
      return (
        this.orderData.total +
        this.deliveryCharge() +
        this.dutyCharge() +
        this.vatCharge()
      );
    },
  },
};
</script>
